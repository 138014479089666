import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { COLORS } from "../colors/colors.js";

import Header from "./header.js";
import SectionTitle from "./sectionTitle.js";
import OrderTotal from "./orderTotal.js";
import BackModal from "./backModal.js";
import pendantData from "../productData/pendants.js";
import NumberFormat from "react-number-format";
import { ScrollToTop } from "./scrollToTop.js";

const ChoosePendants = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();

  // window.addEventListener("popstate", () => {
  //   goBack();
  // });

  const [totalSelected, setTotalSelected] = useState(0);
  const [priceTotal, setPriceTotal] = useState(
    location.state.orderInfo.totalPrice
  );
  const [baseCanopyPrice] = useState(
    location.state.orderInfo.baseCanopyPrice
  );

  const [chosenPendant, setChosenPendant] = useState({
    name: "initial",
    photo: "",
    smPartNumFluted: "",
    smPartNumBelvedere: "",
    smPartNumRound: "",
    smPartNumNebular: "",
    smPartNumTraditional: "",
    mdPartNumFluted: "",
    mdPartNumBelvedere: "",
    mdPartNumRound: "",
    mdPartNumNebular: "",
    mdPartNumTraditional: "",
    lgPartNumFluted: "",
    lgPartNumBelvedere: "",
    lgPartNumRound: "",
    lgPartNumNebular: "",
    lgPartNumTraditional: "",
    smPhoto: "",
    mdPhoto: "",
    lgPhoto: "",
    smPrice: "",
    mdPrice: "",
    lgPrice: "",
    border: "",
    smallSelected: 0,
    mediumSelected: 0,
    largeSelected: 0,
    totalSelected: 0,
  });

  const [pendantTotal] = useState(location.state.orderInfo.pendantTotal);
  const [allChosenPendants, setAllChosenPendants] = useState([]);

  // this is just used to trigger stateRefresh for inner components
  const [triggerStateChange, setTriggerStateChange] = useState(false);

  const [quantitySmall, setQuantitySmall] = useState("Choose Quantity");
  const [quantityMedium, setQuantityMedium] = useState("Choose Quantity");
  const [quantityLarge, setQuantityLarge] = useState("Choose Quantity");

  /**
    On Browser back press //GOT TO FIX THIS
   */

  /** All pendants save from navigation */
  useEffect(() => {
    setAllChosenPendants(location.state.orderInfo.pendants);
    getTotal();
    showChosenPendants(allChosenPendants);
  }, [allChosenPendants]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAllAdditionalCords = () => {
    var allCords = [];
    var id = 0;
    for (var i = 0; i < allChosenPendants.length; i++) {
      if (allChosenPendants[i].smallSelected !== 0) {
        for (var j = 0; j < allChosenPendants[i].smallSelected; j++) {
          allCords.push({
            pendantID: id,
            quantity: 0,
            title: allChosenPendants[i].name,
            size: "Small",
            price: 0,
          });
          id++;
        }
      }
      if (allChosenPendants[i].mediumSelected !== 0) {
        for (var n = 0; n < allChosenPendants[i].mediumSelected; n++) {
          allCords.push({
            pendantID: id,
            quantity: 0,
            title: allChosenPendants[i].name,
            size: "Medium",
            price: 0,
          });
          id++;
        }
      }
      if (allChosenPendants[i].largeSelected !== 0) {
        for (var m = 0; m < allChosenPendants[i].largeSelected; m++) {
          allCords.push({
            pendantID: id,
            quantity: 0,
            title: allChosenPendants[i].name,
            size: "Large",
            price: 0,
          });
          id++;
        }
      }
    }
    return allCords;
  };

  /**
    Go to the next page
   */
  const nextPage = () => {
    var newinfo = location.state.orderInfo;
    newinfo.finishColorName = "";
    newinfo.finishColorPhoto = "";
    newinfo.finishCordName = "";
    newinfo.finishCordPhoto = "";
    newinfo.additionalCords = setAllAdditionalCords();
    let path = `/chooseFinish`;
    history.push({
      pathname: path,
      state: { orderInfo: newinfo },
    });
  };

  const checkForEnableNext = () => {
    var correct = 0;
    for (var i = 0; i < allChosenPendants.length; i++) {
      if (allChosenPendants[i].housing !== "") {
        correct++;
      }
    }
    if (
      correct === allChosenPendants.length &&
      totalSelected === pendantTotal
    ) {
      setIsDisabled(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setIsDisabled(true);
    }
  };
  /**
    Get Total of all chosen pendants
   */
  const getTotal = () => {
    var total = 0;
    for (var i = 0; i < location.state.orderInfo.pendants.length; i++) {
      total += location.state.orderInfo.pendants[i].totalSelected;
    }
    if (total !== location.state.orderInfo.pendantTotal) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    setTotalSelected(total);
    setCorrectTotalPrice();
  };

  /**
    Add pendants to all chosen pendants or update the chosen pendant
   */
  const addToAllChosenPendants = (item) => {
    var newArray = allChosenPendants;
    newArray.push(item);
    setAllChosenPendants(newArray);
  };

  /**
    Go Back to previous page
   */
  const goBack = () => {
    let path = `/chooseElectricalType`;
    history.push({
      pathname: path,
      state: { orderInfo: location.state.orderInfo },
    });
  };

  /**
    Show the chosen pendants in the Order Total
   */
  const showChosenPendants = (allChosenPendants) => {
    const selectedImageContainer = {
      width: "100%",
      height: "100px",
    };

    const selectedImage = {
      borderRadius: 10,
      maxWidth: "100%",
      maxHeight: "100%",
    };

    var UIitems = [];
    for (let i = 0; i < allChosenPendants.length; i++) {
      UIitems.push(
        <div key={allChosenPendants[i].name} style={{ marginBottom: "3rem" }}>
          <div style={{}}>
            <div className="row" style={{ margin: 0 }}>
              <div>{allChosenPendants[i].name}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              <div>Housing: {allChosenPendants[i].housing}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              {allChosenPendants[i].smallSelected !== 0 ? (
                <p style={{ marginRight: 5 }}>
                  Small: x{allChosenPendants[i].smallSelected}{" "}
                  {(allChosenPendants[i].largeSelected !== 0 &&
                    allChosenPendants[i].mediumSelected === 0) ||
                  allChosenPendants[i].mediumSelected !== 0 ? (
                    <b>•</b>
                  ) : (
                    <p />
                  )}
                  {"   "}
                </p>
              ) : (
                <div />
              )}
              {allChosenPendants[i].smPrice === allChosenPendants[i].mdPrice &&
              allChosenPendants[i].mdPrice === allChosenPendants[i].lgPrice ? (
                allChosenPendants[i].mediumSelected !== 0 ? (
                  <p style={{ marginRight: 5 }}>
                    {" "}
                    Standard Size: x{allChosenPendants[i].mediumSelected}{" "}
                    {"   "}
                  </p>
                ) : (
                  <div />
                )
              ) : allChosenPendants[i].mediumSelected !== 0 ? (
                <p style={{ marginRight: 5 }}>
                  {" "}
                  Med: x{allChosenPendants[i].mediumSelected} {"   "}
                </p>
              ) : (
                <div />
              )}
              {allChosenPendants[i].largeSelected !== 0 ? (
                <p>
                  {allChosenPendants[i].mediumSelected !== 0 ? <b>•</b> : ""}{" "}
                  Large: x{allChosenPendants[i].largeSelected}
                </p>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div style={selectedImageContainer}>
            <img
              src={allChosenPendants[i].photo}
              style={selectedImage}
              alt=""
            />
          </div>
        </div>
      );
    }
    return UIitems;
  };

  const givePendantsCorrectHousing = (name, putName) => {
    for (var i = 0; i < allChosenPendants.length; i++) {
      if (isPendantInSection(allChosenPendants[i], name)) {
        allChosenPendants[i].housing = putName;
      }
    }
  };

  /**
    String for the paragraph of the header
   */
  const headerParagraph = () => {
    return (
      <div>
        <p>
          Choose your preferred pendants from the selections below. If more than
          one pendant shade size is offered, you'll be able to select your
          preferred size once you click on the pendant shade. If more than one
          electrical housing style is available, you'll also be asked to select
          your preferred electrical housing style.
        </p>
        <p>
          All pendants can be mixed and matched, but note that electrical
          housings do not match across all pendant styles.
        </p>
        <p>
          Once your pendant selections equal the quantity of ports in your
          chosen canopy, you'll be able to proceed with selecting your finish
          and cord colors.
        </p>
      </div>
    );
  };

  /**
    Handle the pendant that is chosen
   */
  const handleChosenPendant = (pendant) => {
    // TODO: refactor the initial state logic out eventually. Should only be called once
    if (!pendant.housing) {
      pendant.housing = "";
    }
    if (!pendant.smallSelected) {
      pendant.smallSelected = 0;
    }
    if (!pendant.mediumSelected) {
      pendant.mediumSelected = 0;
    }
    if (!pendant.largeSelected) {
      pendant.largeSelected = 0;
    }
    if (!pendant.totalSelected) {
      pendant.totalSelected = 0;
    }

    var found = false;
    for (var i = 0; i < allChosenPendants.length; i++) {
      if (allChosenPendants[i].name === pendant.name) {
        setQuantitySmall(allChosenPendants[i].smallSelected);
        setQuantityMedium(allChosenPendants[i].mediumSelected);
        setQuantityLarge(allChosenPendants[i].largeSelected);
        setChosenPendant(allChosenPendants[i]);
        found = true;
      }
    }
    if (!found) {
      setQuantitySmall(pendant.smallSelected);
      setQuantityMedium(pendant.mediumSelected);
      setQuantityLarge(pendant.largeSelected);
      setChosenPendant(pendant);
    }
  };

  /**
    Set the correct prices
   */
  const setCorrectTotalPrice = () => {
    var initPrice = 0;
    var populated = 0;
    for (var i = 0; i < location.state.orderInfo.pendants.length; i++) {
      initPrice +=
        location.state.orderInfo.pendants[i].smallSelected *
          location.state.orderInfo.pendants[i].smPrice +
        location.state.orderInfo.pendants[i].mediumSelected *
          location.state.orderInfo.pendants[i].mdPrice +
        location.state.orderInfo.pendants[i].largeSelected *
          location.state.orderInfo.pendants[i].lgPrice 
      if (i === allChosenPendants.length - 1) {
        populated = 1;
      }
    }
    initPrice += location.state.orderInfo.baseCanopyPrice
    if (populated === 1) {
      setPriceTotal(initPrice);
      location.state.orderInfo.totalPrice = initPrice;
    }
    if (location.state.orderInfo.pendants.length === 0) {
      setPriceTotal(0);
    }
  };

  /*
    Get the pendant section name from the pendant name 
    Pendant name = section name + the pendant color. So "Rock Crystal Amber" etc.
    We want to get "Rock Crystal" from this
  */
  const parseName = (str) => {
    var splitted = str.trim().split(" ");
    if(splitted.length >= 2) {
      return splitted.slice(0, splitted.length - 1).join(" ");
    }
    return str;
  };

  /*
    Given a pendant name, find the housings for that pendant
   */
  const getHousingsByPendantName = (pendantName) => {
    const section = [
      // put all section objects in an array
      ...Object.values(pendantData.led.sections),
      ...Object.values(pendantData.incandescent.sections),
    ].find((section) => section.name === parseName(pendantName)); // get section that matches pendant name

    return section ? section.housings : [];
  };

  /**
    Handle the Save Changes when choosing the pendants
   */
  const handleSave = () => {
    var found = false;
    for (var i = 0; i < allChosenPendants.length; i++) {
      if (allChosenPendants[i].name === chosenPendant.name) {
        allChosenPendants[i] = chosenPendant;
        found = true;
        if (allChosenPendants[i].totalSelected === 0) {
          allChosenPendants.splice(i, 1);
        }
      }
    }
    if (!found) {
      if (chosenPendant.totalSelected !== 0) {
        addToAllChosenPendants(chosenPendant);
      } else {
      }
    } else {
      if (chosenPendant.totalSelected === 0) {
      } else {
      }
    }

    /*
      Apply housing to all selected pendants that are in the same section
     */
    for (var n = 0; n < allChosenPendants.length; n++) {
      if (isPendantInSection(allChosenPendants[n], chosenPendant.name)) {
        chosenPendant.housing = allChosenPendants[n].housing;
        /*
          Auto-select first housing by default
         */
        const housingsForPendant = getHousingsByPendantName(chosenPendant.name);
        if (housingsForPendant.length > 0) {
          housingClickHandler(
            parseName(chosenPendant.name),
            housingsForPendant[0].name
          );
        }
      }
    }
    setCorrectTotalPrice();
    checkForEnableNext();

    setChosenPendant({
      name: "initial",
      photo: "",
      smPartNumFluted: "",
      smPartNumBelvedere: "",
      smPartNumRound: "",
      smPartNumNebular: "",
      smPartNumTraditional: "",
      mdPartNumFluted: "",
      mdPartNumBelvedere: "",
      mdPartNumRound: "",
      mdPartNumNebular: "",
      mdPartNumTraditional: "",
      lgPartNumFluted: "",
      lgPartNumBelvedere: "",
      lgPartNumRound: "",
      lgPartNumNebular: "",
      lgPartNumTraditional: "",
      smPhoto: "",
      mdPhoto: "",
      lgPhoto: "",
      smPrice: "",
      mdPrice: "",
      lgPrice: "",
      border: "",
      housing: "",
      smallSelected: 0,
      mediumSelected: 0,
      largeSelected: 0,
      totalSelected: 0,
    });
  };

  /**
    Handle the amount chosen from the modal
   */
  const handleAmount = (num, type) => {
    switch (type) {
      case "s": {
        if (num < chosenPendant.smallSelected) {
          setTotalSelected(totalSelected - (chosenPendant.smallSelected - num));
          chosenPendant.smallSelected = num;
          chosenPendant.totalSelected =
            chosenPendant.smallSelected +
            chosenPendant.mediumSelected +
            chosenPendant.largeSelected;
          setQuantitySmall(num);
        } else {
          if (
            totalSelected + (num - chosenPendant.smallSelected) <=
            pendantTotal
          ) {
            setTotalSelected(
              totalSelected + (num - chosenPendant.smallSelected)
            );
            chosenPendant.smallSelected = num;
            chosenPendant.totalSelected =
              chosenPendant.smallSelected +
              chosenPendant.mediumSelected +
              chosenPendant.largeSelected;
            setQuantitySmall(num);
          } else {
            alert(
              "Cannot choose more than " + String(pendantTotal) + " pendants."
            );
          }
        }
        break;
      }
      case "m": {
        if (num < chosenPendant.mediumSelected) {
          setTotalSelected(
            totalSelected - (chosenPendant.mediumSelected - num)
          );
          chosenPendant.mediumSelected = num;
          chosenPendant.totalSelected =
            chosenPendant.smallSelected +
            chosenPendant.mediumSelected +
            chosenPendant.largeSelected;
          setQuantityMedium(num);
        } else {
          if (
            totalSelected + (num - chosenPendant.mediumSelected) <=
            pendantTotal
          ) {
            setTotalSelected(
              totalSelected + (num - chosenPendant.mediumSelected)
            );
            chosenPendant.mediumSelected = num;
            chosenPendant.totalSelected =
              chosenPendant.smallSelected +
              chosenPendant.mediumSelected +
              chosenPendant.largeSelected;
            setQuantityMedium(num);
          } else {
            alert(
              "Cannot choose more than " + String(pendantTotal) + " pendants."
            );
          }
        }
        break;
      }
      case "l": {
        if (num < chosenPendant.largeSelected) {
          setTotalSelected(totalSelected - (chosenPendant.largeSelected - num));
          chosenPendant.largeSelected = num;
          chosenPendant.totalSelected =
            chosenPendant.smallSelected +
            chosenPendant.mediumSelected +
            chosenPendant.largeSelected;
          setQuantityLarge(num);
        } else {
          if (
            totalSelected + (num - chosenPendant.largeSelected) <=
            pendantTotal
          ) {
            setTotalSelected(
              totalSelected + (num - chosenPendant.largeSelected)
            );
            chosenPendant.largeSelected = num;
            chosenPendant.totalSelected =
              chosenPendant.smallSelected +
              chosenPendant.mediumSelected +
              chosenPendant.largeSelected;
            setQuantityLarge(num);
          } else {
            alert(
              "Cannot choose more than " + String(pendantTotal) + " pendants."
            );
          }
        }
        break;
      }

      default:
        break;
    }
  };

  const modalSplit = {
    marginBottom: 40,
    color: COLORS.hmBrown,
    borderBottom: `1px solid ${COLORS.hmBrown}`,
    display: "flex",
    justifyContent: "space-between",
  };

  /**
    Modal opened on pendant press to choose quantity.
   */
  const chooseQuantityModal = () => {
    const cardStyle = {
      width: "12rem",
      borderColor: "#FFF",
      maxWidth: "100%",
      marginRight: 0,
      marginLeft: 0,
    };

    const numCards = chosenPendant.smPhoto ? 3 : 1;

    const smallCard = chosenPendant.smPhoto ? (
      <div className="card" style={cardStyle}>
        <img
          className="card-img-top"
          src={chosenPendant.smPhoto}
          alt=""
          style={{
            width: "100%",
            backgroundColor: COLORS.hmGray,
          }}
        />
        <div className="card-body">
          <p className="card-title" style={{ color: COLORS.hmBrown }}>
            Small
          </p>
          <div className="row justify-content-between">
            <p style={{ margin: 15, color: "#000000", fontWeight: "bold" }}>
              {" "}
              Quantity:{" "}
            </p>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  margin: 10,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {quantitySmall}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ maxHeight: 200, overflowY: "scroll" }}
              >
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(0, "s");
                    //setQuantitySmall("Quantity: 1");
                  }}
                >
                  0
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(1, "s");
                    //setQuantitySmall("Quantity: 1");
                  }}
                >
                  1
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(2, "s");
                    //setQuantitySmall("Quantity: 2", "s");
                  }}
                >
                  2
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(3, "s");
                    //setQuantitySmall("Quantity: 3", "s");
                  }}
                >
                  3
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(4, "s");
                    //setQuantitySmall("Quantity: 4", "s");
                  }}
                >
                  4
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(5, "s")}
                >
                  5
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(6, "s")}
                >
                  6
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(7, "s")}
                >
                  7
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(8, "s")}
                >
                  8
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(9, "s")}
                >
                  9
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(10, "s")}
                >
                  10
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(11, "s")}
                >
                  11
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(12, "s")}
                >
                  12
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(13, "s")}
                >
                  13
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(14, "s")}
                >
                  14
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(15, "s")}
                >
                  15
                </p>
              </div>
            </div>
          </div>
          <div style={modalSplit} />
        </div>
      </div>
    ) : (
      <div />
    );

    const mediumCard = chosenPendant.mdPhoto ? (
      <div className="card" style={cardStyle}>
        <img
          className="card-img-top"
          src={chosenPendant.mdPhoto}
          alt=""
          style={{
            width: "100%",
            backgroundColor: COLORS.hmGray,
          }}
        />
        <div className="card-body">
          <p className="card-title" style={{ color: COLORS.hmBrown }}>
            {numCards === 1 ? 'Standard' : 'Medium'}
          </p>
          <div className="row justify-content-between">
            <p style={{ margin: 15, color: "#000000", fontWeight: "bold" }}>
              {" "}
              Quantity:{" "}
            </p>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  margin: 10,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#000000", //color used to be ababab
                  fontWeight: "bold",
                }}
              >
                {quantityMedium}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ maxHeight: 200, overflowY: "scroll" }}
              >
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(0, "m");
                    //setQuantitySmall("Quantity: 1");
                  }}
                >
                  0
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(1, "m")}
                >
                  1
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(2, "m")}
                >
                  2
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(3, "m")}
                >
                  3
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(4, "m")}
                >
                  4
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(5, "m")}
                >
                  5
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(6, "m")}
                >
                  6
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(7, "m")}
                >
                  7
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(8, "m")}
                >
                  8
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(9, "m")}
                >
                  9
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(10, "m")}
                >
                  10
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(11, "m")}
                >
                  11
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(12, "m")}
                >
                  12
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(13, "m")}
                >
                  13
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(14, "m")}
                >
                  14
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(15, "m")}
                >
                  15
                </p>
              </div>
            </div>
          </div>
          <div style={modalSplit} />
        </div>
      </div>
    ) : (
      <div />
    );

    const largeCard = chosenPendant.lgPhoto ? (
      <div className="card" style={cardStyle}>
        <img
          className="card-img-top"
          src={chosenPendant.lgPhoto}
          alt=""
          style={{
            width: "100%",
            backgroundColor: COLORS.hmGray,
          }}
        />
        <div className="card-body">
          <p className="card-title" style={{ color: COLORS.hmBrown }}>
            Large
          </p>
          <div className="row justify-content-between">
            <p style={{ margin: 15, color: "#000000", fontWeight: "bold" }}>
              {" "}
              Quantity:{" "}
            </p>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  margin: 10,
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {quantityLarge}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ maxHeight: 200, overflowY: "scroll" }}
              >
                <p
                  className="dropdown-item"
                  onClick={() => {
                    handleAmount(0, "l");
                    //setQuantitySmall("Quantity: 1");
                  }}
                >
                  0
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(1, "l")}
                >
                  1
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(2, "l")}
                >
                  2
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(3, "l")}
                >
                  3
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(4, "l")}
                >
                  4
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(5, "l")}
                >
                  5
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(6, "l")}
                >
                  6
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(7, "l")}
                >
                  7
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(8, "l")}
                >
                  8
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(9, "l")}
                >
                  9
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(10, "l")}
                >
                  10
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(11, "l")}
                >
                  11
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(12, "l")}
                >
                  12
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(13, "l")}
                >
                  13
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(14, "l")}
                >
                  14
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => handleAmount(15, "l")}
                >
                  15
                </p>
              </div>
            </div>
          </div>
          <div style={modalSplit} />
        </div>
      </div>
    ) : (
      <div />
    );

    return (
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content" style={{ padding: 70 }}>
            <div className="row">
              <div className="col-md-8">
                <div className="modal-body">
                  <div
                    className="row justify-content-between"
                    style={{
                      borderBottom: `1px solid ${COLORS.hmBrown}`,
                      paddingBottom: 20,
                    }}
                  >
                    <div className="col-md-12">
                      <h3
                        className="modal-title"
                        id="exampleModalLongTitle"
                        style={{ color: COLORS.hmBrown }}
                      >
                        Select size and quantity - {chosenPendant.name}
                      </h3>
                      <h4>
                      <NumberFormat
                        value={chosenPendant.mdPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => value}
                      /></h4>
                      <h5 style={{ marginTop: 20 }}>
                        Total Selected: {totalSelected}/{pendantTotal}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    {smallCard}
                    {mediumCard}
                    {largeCard}
                  </div>
                </div>
              </div>

              <div
                className="col-md-4"
                style={{
                  width: "100%",
                  height: 500,
                }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleSave()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img
                  src={chosenPendant.photo}
                  style={{
                    height: "inherit",
                    width: "inherit",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <button
                type="button"
                style={{
                  borderRadius: 0,
                  minWidth: 170,
                  maxWidth: 220,
                  backgroundColor: COLORS.hmBrown,
                  borderColor: COLORS.hmBrown,
                  float: "right",
                }}
                className="btn btn-secondary btn-lg"
                data-dismiss="modal"
                onClick={() => handleSave()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const card = {
    maxWidth: "100%",
    cursor: "pointer",
  };

  const cardImgStyle = {
    backgroundColor: COLORS.hmGray,
  };

  const PendantCard = ({ sectionTitle, pendant }) => {
    // pendant options is selected if it's one of the selected pendants
    const pendantName = `${sectionTitle} ${pendant.name}`;
    const consenPendantNames = allChosenPendants.map(
      (chosenPendant) => chosenPendant.name
    );
    const isUserSelected = consenPendantNames.includes(pendantName);
    const background = isUserSelected ? { border: "2px solid gray" } : {};

    const clickHandler = () => {
      handleChosenPendant({
        name: `${sectionTitle} ${pendant.name}`,
        photo: pendant.photo,
        smPartNumFluted: pendant.smPartNumFluted,
        smPartNumBelvedere: pendant.smPartNumBelvedere,
        smPartNumRound: pendant.smPartNumRound,
        smPartNumNebular: pendant.smPartNumNebular,
        smPartNumTraditional: pendant.smPartNumTraditional,
        mdPartNumFluted: pendant.mdPartNumFluted,
        mdPartNumBelvedere: pendant.mdPartNumBelvedere,
        mdPartNumRound: pendant.mdPartNumRound,
        mdPartNumNebular: pendant.mdPartNumNebular,
        mdPartNumTraditional: pendant.mdPartNumTraditional,
        lgPartNumFluted: pendant.lgPartNumFluted,
        lgPartNumBelvedere: pendant.lgPartNumBelvedere,
        lgPartNumRound: pendant.lgPartNumRound,
        lgPartNumNebular: pendant.lgPartNumNebular,
        lgPartNumTraditional: pendant.lgPartNumTraditional,
        smPhoto: pendant.smPhoto,
        mdPhoto: pendant.mdPhoto,
        lgPhoto: pendant.lgPhoto,
        smPrice: pendant.smPrice,
        mdPrice: pendant.mdPrice,
        lgPrice: pendant.lgPrice,
      });
    };

    return (
      <div className="col-md-3" style={card}>
        <div
          className="card"
          style={{ width: "8rem", ...background }}
          data-toggle="modal"
          data-target="#exampleModalCenter"
          onClick={clickHandler}
        >
          <img
            className="card-img-top"
            src={pendant.photo}
            alt=""
            style={cardImgStyle}
          />
          <div className="card-body">
            <h6 className="card-title">{pendant.name}</h6>
          </div>
        </div>
      </div>
    );
  };

  const housingClickHandler = (sectionTitle, title) => {
    givePendantsCorrectHousing(sectionTitle, title);
    checkForEnableNext();
    // refresh state so pendant selection is picked up
    // this is just to trigger a state change for inner components
    const flipState = triggerStateChange ? false : true;
    setTriggerStateChange(flipState);
  };

  /*
    Returns true if a given pendant falls within the given section.
    Match is made by checking if section title is a case-insensitive substring of a pendant name.
    Any color names in the section titles are also excluded.
    ex: 
        sectionTitle: "Apothecary 8x8"
        pendant: { name: "Apothecary 8x8 bronze" }
        returns: true
        sectionTitle: "Apothecary 8x8 clear"
        pendant: { name: "Apothecary 8x8 bronze" }
        returns: true
        sectionTitle: "Apothecary 5x10"
        pendant: { name: "Apothecary 8x8 bronze" }
        returns: false
   */
  const isPendantInSection = (pendant, sectionTitle) => {
    const clean = (str) =>
      str.toLowerCase().replace(/amber|bronze|smoke|clear/gi, "");

    return clean(pendant.name).includes(clean(sectionTitle));
  };

  /*
    Returns an array wit the list of pendants that belong to the given section.
    see: getSelectedPendantsForSection
   */
  const getSelectedPendantsForSection = (sectionTitle) => {
    const foundPendants = allChosenPendants.filter((pendant) => {
      return isPendantInSection(pendant, sectionTitle);
    });

    return foundPendants;
  };

  /*
    Render a card for any Housing
    Includes all housing selection rules
   */
  const HousingCard = ({ title, image, sectionTitle }) => {
    /*
      Decide if this card should be highlighted as "selected"
      Happens if combination of section title + title is in one of the pendants, then it is selected
    */
    const housingName = `${sectionTitle} ${title}`;
    const chosenPendantsForThisSection = getSelectedPendantsForSection(
      sectionTitle
    );
    const chosenHousingNamesForThisSection = chosenPendantsForThisSection.map(
      (pendant) => `${sectionTitle} ${pendant.housing}`
    );
    const isUserSelected = chosenHousingNamesForThisSection.includes(
      housingName
    );

    /*
      Decide if this card should be "enabled" for editing
      Happens if any pendants in the same section as this card have been selected
    */
    const isUserEnabled =
      sectionTitle && getSelectedPendantsForSection(sectionTitle).length > 0;

    const background = {
      width: "8rem",
      pointerEvents: "none",
      opacity: "0.4",
    };

    if (isUserSelected) {
      background.border = "2px solid gray";
    }

    if (isUserEnabled) {
      background.opacity = "1";
      background.pointerEvents = "auto";
    }

    const clickHandler = () => {
      givePendantsCorrectHousing(sectionTitle, title);
      checkForEnableNext();
      // refresh state so pendant selection is picked up
      // this is just to trigger a state change for inner components
      const flipState = triggerStateChange ? false : true;
      setTriggerStateChange(flipState);
    };

    return (
      <div className="col-md-3" style={card}>
        <div className="card" style={background} onClick={clickHandler}>
          <img
            className="card-img-top"
            src={image}
            alt=""
            style={cardImgStyle}
          />
          <div className="card-body">
            <h6 className="card-title">{title}</h6>
          </div>
        </div>
      </div>
    );
  };

  /**
    Complete Gem Section
   */
  const showSection = (section) => {
    const {
      name: sectionTitle,
      otherText: sectionOtherText,
      pendants,
      housings,
    } = section;

    const pendantCards = pendants.map((pendant) => {
      return (
        <PendantCard
          key={pendant.name}
          sectionTitle={sectionTitle}
          pendant={pendant}
        />
      );
    });

    const housingCards = housings.map((housing) => {
      return (
        <HousingCard
          key={housing.name}
          sectionTitle={sectionTitle}
          title={housing.name}
          image={housing.photo}
        />
      );
    });

    return (
      <div>
        <SectionTitle title={sectionTitle} otherText={sectionOtherText} />
        <div className="row">
          <div className="col-md-8">
            <div className="row">{pendantCards}</div>
          </div>
          <div className="col-md-4">
            <div className="row justify-content-end">{housingCards}</div>
          </div>
        </div>
      </div>
    );
  };

  const showCorrectPendants = () => {
    if (location.state.orderInfo.electricalTypeName.toLowerCase().includes('led')) {
      const { sections } = pendantData.led;
      return (
        <div>
          {showSection(sections.gemSection)}
          {showSection(sections.rockCrystalSection)}
          {showSection(sections.vesselSection)}
          {showSection(sections.gaiaSection)}
          {showSection(sections.mesaSection)}
          {showSection(sections.asterFloretSection)}
          {showSection(sections.asterGeoSection)}
          {showSection(sections.asterZirconSection)}
          {showSection(sections.novaFloretSection)}
          {showSection(sections.novaGeoSection)}
          {showSection(sections.novaZirconSection)}
          {showSection(sections.meteoFloretSection)}
          {showSection(sections.meteoGeoSection)}
          {showSection(sections.meteoZirconSection)}
          {showSection(sections.aaltoSection)}
          {showSection(sections.cabochonBlackMarbleSection)}
          {showSection(sections.cabochonWhiteMarbleSection)}
          {showSection(sections.cabochonTravertineSection)}
          {showSection(sections.cabochonMetalSection)}
        </div>
      );
    } else if (location.state.orderInfo.electricalTypeName.toLowerCase().includes('incandescent')) {
      const { sections } = pendantData.incandescent;
      return (
        <div>
          {showSection(sections.coppaSection)}
          {showSection(sections.ellisseSection)}
          {showSection(sections.terraSection)}
          {showSection(sections.asterSection)}
          {showSection(sections.novaSection)}
          {showSection(sections.meteoSection)}
        </div>
      );
    }
  };

  const header = {
    textAlign: "flex-start",
    marginTop: 40,
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    maxWidth: "80%",
    fontSize: 18,
  };

  const totalChosenPendantsText = {
    textAlign: "center",
    maxWidth: "80%",
    fontSize: 18,
  };

  const appContainer = {
    paddingRight: 70,
    paddingLeft: 70,
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div>
        <div className="row">
          <div className="col-md-9" style={appContainer}>
            <h2 style={header}>Choose Your Pendants</h2>
            <div style={headerP}>{headerParagraph()}</div>
            <div style={totalChosenPendantsText}>
              <h5
                style={{
                  border: "2px solid",
                  color: COLORS.hmBrown,
                  fontWeight: "bold",
                  maxWidth: 200,
                  padding: 20,
                }}
              >
                {totalSelected} / {pendantTotal} Chosen{" "}
              </h5>
            </div>
            {showCorrectPendants()}
          </div>
          <OrderTotal
            totalTag="Total"
            priceTotal={priceTotal}
            isDisabled={isDisabled}
            nextPage={nextPage}
            chosenProducts={{
              chosenCanopy: location.state.orderInfo.canopyName,
              chosenElectricalType: `${location.state.orderInfo.electricalTypeName} ${location.state.orderInfo.electricalTypePower}`,
              showChosenPendants: showChosenPendants(allChosenPendants),
            }}
            chosenImages={{
              chosenCanopyImage: location.state.orderInfo.canopyPhoto,
              chosenElectricalTypeImage: "", // don't show for this
            }}
            chosenAdditionalCords={{}}
          />
          {chooseQuantityModal()}
          <BackModal
            title="Are you sure?"
            description="Your Pendant changes will be lost."
            goBack={goBack}
          />
        </div>
      </div>
    </div>
  );
};

export default ChoosePendants;
